import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "./components/account/Login";
// These are included here as they include styling that is required for the login screen.
import Logout from "./components/account/Logout";
import Register from "./components/account/Register";
import Verify from "./components/account/Verify";
import ForgotPassword from "./components/account/ForgotPassword";
import ResetPassword from "./components/account/ResetPassword";
import Dashboard from "./components/common/Dashboard";
import ExpenseList from "./components/expense/ExpenseList";
import ExpenseForm from "./components/expense/ExpenseForm";
import Profile from "./components/profile";
/*import RoleForm from "./components/roles/RoleForm";
import RoleList from "./components/roles/RoleList";
import DailyTimeSheets from "./components/timesheets/DailyTimeSheets";
import TimesheetForm from "./components/timesheets/TimesheetForm";
import TimesheetList from "./components/timesheets/TimesheetList";
import UserList from "./components/user/UserList";
import UserForm from "./components/user/UserForm";*/
import { CompanyProvider } from "./lib/context/CompanyContext";
import { StatusMessageProvider } from "./lib/context/StatusMessageContext";
/* import { SessionProvider } from "./lib/context/SessionContext";
import "./App.scss";
const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      sessionStorage.getItem("token") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);
*/
function App() {
  return (
    <div>
      <CompanyProvider>
        <StatusMessageProvider>
          <Router>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />
              {/*<Route exact path="/register" component={Register} />
              <Route exact path="/verify/:uuid" component={Verify} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route
                exact
                path="/forgot-password/:token"
                component={ResetPassword}
              />
              <ProtectedRoute exact path="/dashboard" component={Dashboard} />
              <ProtectedRoute exact path="/expenses" component={ExpenseList} />
              <ProtectedRoute exact path="/expense" component={ExpenseForm} />
              <ProtectedRoute
                exact
                path="/expense/:id"
                component={ExpenseForm}
              />
              <ProtectedRoute exact path="/profile" component={Profile} />
               <ProtectedRoute exact path="/roles" component={RoleList} />
              <ProtectedRoute exact path="/role" component={RoleForm} />
              <ProtectedRoute exact path="/role/:id" component={RoleForm} />
              <ProtectedRoute
                exact
                path="/timesheets"
                component={TimesheetList}
              />
              <ProtectedRoute
                exact
                path="/timesheet"
                component={TimesheetForm}
              />
              <ProtectedRoute exact path="/users" component={UserList} />
              <ProtectedRoute exact path="/user" component={UserForm} />
              <ProtectedRoute exact path="/user/:id" component={UserForm} /> 
              <ProtectedRoute exact path="/logout" component={Logout} />*/}
            </Switch>
          </Router>
        </StatusMessageProvider>
      </CompanyProvider>
    </div>
  );
}

export default App;
